import Vue from 'vue';
import es from 'vuetify/src/locale/es';
import Vuetify, { VNavigationDrawer } from 'vuetify/lib';
/**
 * Registrar componentes
 */
Vue.use(Vuetify, {
  components: {
    VNavigationDrawer,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#ea4335',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
